<template>
  <svg :height="`${height}px` || '24px'" :width="`${width}px` || '24px'" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5 14C6.05228 14 6.5 13.5523 6.5 13C6.5 12.4477 6.05228 12 5.5 12C4.94772 12 4.5 12.4477 4.5 13C4.5 13.5523 4.94772 14 5.5 14Z" :fill="color" />
    <path d="M12.5 14C13.0523 14 13.5 13.5523 13.5 13C13.5 12.4477 13.0523 12 12.5 12C11.9477 12 11.5 12.4477 11.5 13C11.5 13.5523 11.9477 14 12.5 14Z" :fill="color" />
    <path
      d="M14.275 3.77437C14.2048 3.68845 14.1163 3.61925 14.0159 3.57179C13.9156 3.52433 13.806 3.49981 13.695 3.5H4.18406L3.9925 2.41313C3.97207 2.29735 3.91151 2.19248 3.82144 2.11693C3.73137 2.04138 3.61756 1.99998 3.5 2H1.5C1.36739 2 1.24021 2.05268 1.14645 2.14645C1.05268 2.24021 1 2.36739 1 2.5C1 2.63261 1.05268 2.75979 1.14645 2.85355C1.24021 2.94732 1.36739 3 1.5 3H3.08063L4.5075 11.0869C4.52793 11.2026 4.58849 11.3075 4.67856 11.3831C4.76863 11.4586 4.88244 11.5 5 11.5H13C13.1326 11.5 13.2598 11.4473 13.3536 11.3536C13.4473 11.2598 13.5 11.1326 13.5 11C13.5 10.8674 13.4473 10.7402 13.3536 10.6464C13.2598 10.5527 13.1326 10.5 13 10.5H5.41937L5.24312 9.5H12.795C12.9684 9.49978 13.1364 9.43966 13.2706 9.32981C13.4047 9.21996 13.4968 9.06713 13.5312 8.89719L14.4312 4.39719C14.4529 4.28828 14.4502 4.17592 14.4231 4.06821C14.3961 3.96051 14.3455 3.86015 14.275 3.77437Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
